<template>
	<el-table height="auto" :data="tableData" border size="mini">
		<el-table-column label="站点" prop="channelCode" min-width="50">
			<template slot-scope="scope">
				 <el-popover
				    width="200"
				    trigger="hover">
					<div>
					    <div>{{scope.row.channel}}</div>
						<div>{{scope.row.website}}</div>
					 </div>
					<div slot="reference">{{scope.row.channelCode}}</div>
				  </el-popover>
			</template>
		</el-table-column>
		<el-table-column label="BM ID" min-width="75">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.bmId"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="BM Name" min-width="90">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.bmName"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="Pixel ID" min-width="75">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.pixelId"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="Pixel Name" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.pixelName"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="Account ID" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.accountId"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="Account Name" min-width="80">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.accountName"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="APP ID" prop="appId" min-width="75">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.appId"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="APP Secret" prop="secret" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.secret"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="Token" prop="token" min-width="150">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.token"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column v-if="false" label="权限状态" prop="token" min-width="100">
			<template slot-scope="scope">
			</template>
		</el-table-column>
		<el-table-column label="发布状态" min-width="80">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
				<el-button v-if="false" class="text-danger" type="text" @click="$emit('delRow', scope.row)">删除</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	export default {
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
